<template>
  <div class="flex-col page">
    <div class="flex-col">
      <div class="flex-col section_1">
        <span class="text text_1">ABOUT US</span>
        <span class="text text_2">关于小羽佳</span>
        <div class="justify-center group_1">
          <div class="flex-col items-start group_2">
            <span class="text text_3">1999年，厦门小羽佳家政股份有限公司的前身“厦门市小羽佳服务有限公司”</span>
            <span class="text text_4">由翟焰女士创建于美丽的鹭岛一厦门。</span>
            <span class="text text_5">经过近二十三年发展，小羽佳已服务用户超400万次。服务产品涵盖保洁收纳、</span>
            <span class="text text_6">
              搬场搬家、家电清洗、保姆月嫂四大类等七十余个产品，并不断研发新的便民产品上线。
            </span>
            <span class="text text_7">2015年2月4日，“厦门小羽佳家政股份有限公司”正式在全国中小企业股份转</span>
            <span class="text text_8">让系统挂牌，成为中国家政行业民营的首家新三板挂牌企业（证券简称：小羽佳；证</span>
            <span class="text text_9">券代码：831877）</span>
            <span class="text text_10">目前，“小羽佳在楼下”的社区门店已覆盖厦门岛内外390余个社区，为周</span>
            <span class="text text_11">边居民提供全方位、多保障、高品质的家政入户服务，成就美好家庭。</span>
          </div>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546746166657162312.png"
            class="image"
          />
        </div>
      </div>
      <div class="flex-col items-start group_3">
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16545054740277647360.png"
          class="image_1"
        />
        <span class="text_12">成就美好家庭</span>
        <div class="flex-col text-wrapper"><span class="text_13">more+</span></div>
        <div class="group_4">
          <div class="section_2"><!--*--></div>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546746166662364975.png"
            class="image_2"
          />
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16545054740599708748.png"
            class="image_3"
          />
        </div>
      </div>
    </div>
    <div class="flex-col items-start group_5">
      <span class="text text_14">CONTACT US</span>
      <span class="text text_15">联系我们</span>
    </div>
    <div class="justify-between group_6">
      <div class="flex-row group_7">
        <div class="flex-row">
          <div class="flex-col group_9">
            <div class="flex-col group_10">
              <div class="flex-row group_11">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546746166531844464.png"
                  class="image_4"
                />
                <span class="text text_16">客户服务</span>
              </div>
              <span class="text text_17">400-6655-888</span>
            </div>
            <div class="flex-col group_12">
              <div class="flex-row">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546746166598425141.png"
                  class="image_5"
                />
                <span class="text text_18">人才招聘</span>
              </div>
              <span class="text_19">zhaopin@xiaoyujia.com</span>
            </div>
          </div>
          <div class="flex-col group_14">
            <div class="section_3"><!--*--></div>
            <div class="section_3 view_1"><!--*--></div>
          </div>
        </div>
        <div class="flex-col group_15">
          <div class="flex-col">
            <div class="flex-row group_17">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546746166643810231.png"
                class="image_6"
              />
              <span class="text text_20">品牌合作</span>
            </div>
            <span class="text_21">franchise@xiaoyujia.com</span>
          </div>
          <div class="flex-col group_18">
            <div class="flex-row">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546746166652164522.png"
                class="image_7"
              />
              <span class="text text_22">联系地址</span>
            </div>
            <span class="text_23">厦门市思明区人才中心A栋6楼601</span>
          </div>
        </div>
      </div>
      <div class="flex-col items-center">
        <img
          src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/UISCK/wwwcode.png"
          class="image_8"
        />
        <span class="text_24">扫一扫 了解更多</span>
      </div>
    </div>
    <div class="lh100 text-c">@1999-2024 www.xiaoyujia.com 厦门小羽佳家政股份有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备11006005号-1</a></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="css">
.text {
  text-transform: uppercase;
}
.section_3 {
  background-color: #939598;
  height: 8.44vw;
}
.page {
  /* padding-bottom: 6.82vw; */
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.group_5 {
  margin-left: 5.47vw;
  margin-top: 4.17vw;
  align-self: flex-start;
}
.group_6 {
  margin-top: 3.8vw;
  padding-left: 5.73vw;
  padding-right: 5.31vw;
}
.section_1 {
  padding: 2.81vw 4.06vw 4.27vw 11.72vw;
  background-color: #f1f2f2;
}
.group_3 {
  padding: 8.02vw 8.02vw 6.25vw;
  position: relative;
}
.text_14 {
  color: #ffcb05;
  font-size: 2.29vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.72vw;
  letter-spacing: 0.092vw;
}
.text_15 {
  margin-top: 0.89vw;
  color: #ffcb05;
  font-size: 1.41vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.3vw;
  letter-spacing: 0.056vw;
}
.group_7 {
  margin-top: 0.47vw;
}
.text_1 {
  align-self: flex-start;
  color: #ffcb05;
  font-size: 1.88vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.075vw;
}
.text_2 {
  margin-top: 0.68vw;
  align-self: flex-start;
  color: #ffcb05;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.09vw;
}
.group_1 {
  margin-top: 0.89vw;
  padding-left: 0.47vw;
}
.image_1 {
  width: 15.21vw;
  height: 9.38vw;
}
.text_12 {
  margin-left: 2.08vw;
  margin-top: 1.04vw;
  color: #e52f2f;
  font-size: 1.82vw;
  font-family: Source Han Sans CN;
  font-weight: 800;
  line-height: 1.82vw;
}
.text-wrapper {
  margin-left: 10vw;
  margin-top: 3.59vw;
  padding: 0.42vw 0 0.47vw;
  border-radius: 0.52vw;
  border: solid 0.052vw #ffcb05;
}
.group_4 {
  height: 30.36vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 1.61vw;
}
.group_15 {
  margin-left: 3.65vw;
  align-self: center;
  width: 24.01vw;
}
.image_8 {
  width: 14.84vw;
  height: 14.9vw;
}
.text_24 {
  margin-top: 1.77vw;
  color: #808285;
  font-size: 1.98vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.82vw;
  letter-spacing: 0.079vw;
}
.group_2 {
  margin-top: 1.88vw;
}
.image {
  margin-left: 5.31vw;
  width: 37.81vw;
  height: 20.05vw;
}
.text_13 {
  margin-left: 0.52vw;
  margin-right: 0.36vw;
  color: #ffcb05;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 0.83vw;
}
.section_2 {
  background-color: #ffdd00;
  width: 100vw;
  height: 2.08vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 28.18vw;
}
.image_2 {
  width: 38.91vw;
  height: 26.25vw;
  position: absolute;
  right: 4.01vw;
  bottom: 0;
}
.image_3 {
  width: 37.6vw;
  height: 10.78vw;
  position: absolute;
  right: 5.42vw;
  top: 0;
}
.group_9 {
  align-self: center;
  width: 18.31vw;
}
.group_14 {
  margin-left: 2.32vw;
  width: 0.1vw;
}
.group_18 {
  margin-top: 4.95vw;
  padding-left: 0.16vw;
}
.text_3 {
  margin-left: 2.19vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 1.04vw;
}
.text_4 {
  margin-top: 0.68vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 0.99vw;
}
.text_5 {
  margin-left: 2.24vw;
  margin-top: 0.94vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.04vw;
}
.text_6 {
  margin-top: 0.73vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.04vw;
}
.text_7 {
  margin-left: 2.19vw;
  margin-top: 0.78vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 1.04vw;
}
.text_8 {
  margin-top: 0.63vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 1.09vw;
}
.text_9 {
  margin-top: 0.73vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 1.04vw;
}
.text_10 {
  margin-left: 2.19vw;
  margin-top: 0.83vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 1.04vw;
}
.text_11 {
  margin-top: 0.73vw;
  color: #282828;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 1.04vw;
}
.group_10 {
  padding: 0 0.31vw;
}
.group_12 {
  margin-top: 5.73vw;
}
.view_1 {
  margin-top: 1.72vw;
}
.group_17 {
  padding: 0 0.26vw;
}
.text_21 {
  margin-top: 0.83vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.67vw;
  letter-spacing: 0.06vw;
}
.text_23 {
  margin-top: 0.89vw;
  color: #808285;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.41vw;
  letter-spacing: 0.06vw;
}
.group_11 {
  padding: 0 0.1vw;
}
.text_17 {
  margin-top: 0.78vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.2vw;
  letter-spacing: 0.06vw;
}
.text_19 {
  margin-top: 0.99vw;
  color: #808285;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.61vw;
  letter-spacing: 0.06vw;
}
.image_6 {
  width: 2.5vw;
  height: 2.5vw;
}
.text_20 {
  margin-left: 0.99vw;
  margin-top: 1.15vw;
  color: #282828;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  line-height: 1.46vw;
  letter-spacing: 0.06vw;
}
.image_7 {
  margin-bottom: 0.16vw;
  width: 2.45vw;
  height: 2.97vw;
}
.text_22 {
  margin: 1.67vw 13.96vw 0 1.25vw;
  color: #282828;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  line-height: 1.46vw;
  letter-spacing: 0.06vw;
}
.image_4 {
  width: 2.66vw;
  height: 2.76vw;
}
.text_16 {
  margin-left: 0.99vw;
  margin-top: 1.35vw;
  color: #282828;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  line-height: 1.46vw;
  letter-spacing: 0.06vw;
}
.image_5 {
  width: 3.28vw;
  height: 2.76vw;
}
.text_18 {
  margin: 1.41vw 8.41vw 0 0.42vw;
  color: #282828;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  line-height: 1.41vw;
  letter-spacing: 0.06vw;
}
</style>
