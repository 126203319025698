<template>
  <div class="flex-col page">
    <div class="flex-col items-start section_1">
    </div>
    <div class="flex-col group_9">
      <div class="flex-row group_10">
        <div class="flex-row group_11">
          <div class="flex-col items-start text-wrapper_4"><span class="text_14">共创条件</span></div>
          <div class="group_12">
            <!-- <p class="text_15">1、全职自主经营；</p>
            <p class="text_16">2、单店加盟费9万三年授权（品牌、平台、小程序）</p>
            <p class="text_17">①【家姐联盟代理权】</p>
            <p class="text_17">②【免第二店加盟费】以下条件满足其一</p>
            <p class="text_18">1）单店年收入过100万</p>
            <p class="text_18">2）连续3个月盈利超2万；</p>
            <p class="text_19">3、单店授权，区域不设代理</p> -->
            <div class="text_15">合作费16.8万 + 5%运营服务费</div>
            <div class="text_16">
              <div>1、品牌、平台、小程序授权3年使用</div>
              <div>2、含3个月陪跑服务咨询</div>
              <div>3、满足条件，新开二店享7折优惠（不限区域）</div>
            </div>
            <!-- <div class="text_15">合作方式二：家姐联盟合伙人1万</div>
            <div class="text_16">
              <div>1、联盟合伙人平台终身授权；</div>
              <div>2、含1个月陪跑服务咨询；</div>
            </div>
            <div class="text_15">服务内容</div>
            <div class="text_16">
              <div>1、专属陪跑服务团队；2、选址装修方案设计；</div>
              <div>3、活动执行方案策划；4、流量运营陪跑；</div>
              <div>5、运营体系搭建；6、股权结构规划；</div>
              <div>7、产业供应链赋能平台支持</div>
            </div> -->
          </div>
          <p class="text_14_1 section_2">共创优势</p>
          <div class="flex-col section_3">
            <span class="text_25">共创运营模式</span>
            <div class="justify-between group_14">
              <div class="flex-col group_16">
                <span class="text_28">业务拓展</span>
                <div class="text_29">链接用户，挖掘需求、开发业绩</div>
              </div>
              <div class="flex-col group_16">
                <span class="text_28">产能招募</span>
                <div class="text_29">保洁师、整理师、 搬家师、家电清洗师、 保姆、育儿嫂等</div>
              </div>
              <div class="flex-col group_16">
                <span class="text_28">关联商品销售</span>
                <div class="text_29">纸箱、气泡膜、保洁 工具、员工服装、整理 收纳辅料、冷链食品等</div>
              </div>
              <div class="flex-col group_16">
                <span class="text_28">共享便民</span>
                <div class="text_29">寄放、托管、代收、歇息、 充电等</div>
              </div>
            </div>
            <div class="flex-col items-start group_18">
              <span class="text_28">社区运营</span>
              <div class="text_29">深入社群、私域链接，盘活用户</div>
            </div>
            <div class="flex-col items-start group_19">
              <span class="text_33 text_34">CONTACT US</span>
              <span class="text_33 text_35">联系我们</span>
            </div>
          </div>
        </div>
        <div class="flex-col items-center group_20">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16556911055701402442.png"
            class="image" />
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16556911055727936380.png"
            class="image_1" />
        </div>
        <img src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/publicImage/1675043952288img1.png"
          class="image_2" />
      </div>
      <div class="justify-between group_22">
        <div class="flex-row group_23">
          <div class="flex-row">
            <div class="flex-col group_25">
              <div class="flex-col group_26">
                <div class="flex-row">
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16556911055732486685.png"
                    class="image_3" />
                  <span class="text_33 text_41">客户服务</span>
                </div>
                <span class="text_33 text_42">400-6655-888</span>
              </div>
              <div class="flex-col group_28">
                <div class="flex-row">
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16556911055731833535.png"
                    class="image_4" />
                  <span class="text_33 text_43">人才招聘</span>
                </div>
                <span class="text_44">zhaopin@xiaoyujia.com</span>
              </div>
            </div>
            <div class="flex-col group_30 view_1">
              <div class="section_4">
                <!--*-->
              </div>
              <div class="section_4 view_3">
                <!--*-->
              </div>
            </div>
          </div>
          <div class="flex-col group_31">
            <div class="flex-col">
              <div class="flex-row group_26">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16556911055738742832.png"
                  class="image_5" />
                <span class="text_33 text_45">品牌合作</span>
              </div>
              <span class="text_46">franchise@xiaoyujia.com</span>
            </div>
            <div class="flex-col group_33">
              <div class="flex-row">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16556911055733731934.png"
                  class="image_6" />
                <span class="text_33 text_47">联系地址</span>
              </div>
              <span class="text_48">厦门市思明区人才中心A栋6楼601</span>
            </div>
          </div>
          <div class="flex-col group_30 view_6">
            <div class="section_4">
              <!--*-->
            </div>
            <div class="section_4 view_3">
              <!--*-->
            </div>
          </div>
        </div>
        <div class="flex-col items-center group_35">
          <img
            src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/UISCK/wwwcode.png"
            class="image_7" />
          <span class="text_49">扫一扫 了解更多</span>
        </div>
      </div>
    </div>
    <div class="lh100 text-c">@1999-2024 www.xiaoyujia.com 厦门小羽佳家政股份有限公司 <a
        href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备11006005号-1</a></div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
    created() {
      document.title = "小羽佳-合伙人";
    },
    methods: {},
  };
</script>

<style scoped lang="css">
  .group_30 {
    width: 0.1vw;
  }

  .section_4 {
    background-color: #939598;
    height: 6.61vw;
  }

  .text_33 {
    text-transform: uppercase;
  }

  .group_26 {
    padding: 0 0.26vw;
  }

  .view_3 {
    margin-top: 1.35vw;
  }

  .page {
    /* padding-bottom: 9.06vw; */
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .section_1 {
    padding: 0.5vw 3.56vw 29.92vw;
    background-image: url('https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/publicImage/wwwHome.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .group {
    margin-top: 15vw;
    padding-left: 8.07vw;
    padding-right: 8.02vw;
  }

  .group_9 {
    margin-top: 6.17vw;
    padding: 0 4.64vw;
  }

  .text {
    color: #ffffff;
    font-size: 2.76vw;
    font-family: Source Han Sans CN;
    font-weight: 700;
    line-height: 2.66vw;
    letter-spacing: 0.21vw;
  }

  .text_1 {
    margin-top: 2.03vw;
    color: #ffffff;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.46vw;
    letter-spacing: 0.12vw;
  }

  .text_2 {
    align-self: flex-start;
    color: #282828;
    font-size: 2.29vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 2.19vw;
    letter-spacing: 0.092vw;
  }

  .group_1 {
    margin-top: 1.77vw;
  }

  .group_10 {
    margin-right: 2.45vw;
    height: 117.76vw;
    position: relative;
  }

  .group_22 {
    margin-top: 10vw;
    padding: 0 3.91vw;
  }

  .group_3 {
    margin-top: 0.34vw;
  }

  .group_11 {
    width: 59.06vw;
    height: 117.76vw;
    position: absolute;
    left: 0;
    top: 0;
  }

  .group_20 {
    padding-top: 3.59vw;
    width: 34.32vw;
    position: absolute;
    right: 0;
    top: 7vw;
  }

  .image_2 {
    width: 30vw;
    height: auto;
    position: absolute;
    right: 28vw;
    bottom: 35vw;
  }

  .text_38 {
    color: #fdb913;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 2.29vw;
    letter-spacing: 0.094vw;
    position: absolute;
    right: 10.52vw;
    bottom: 25.42vw;
  }

  .group_21 {
    position: absolute;
    right: 0.94vw;
    top: 92.71vw;
  }

  .group_23 {
    margin-top: 0.31vw;
  }

  .group_35 {
    margin-right: 13.13vw;
    margin-bottom: 0.42vw;
  }

  .text-wrapper_1 {
    margin-left: 17.5vw;
  }

  .text-wrapper_2 {
    margin-left: 17.4vw;
  }

  .text-wrapper_3 {
    margin-left: 17.4vw;
    width: 6.41vw;
  }

  .group_8 {
    margin-top: 0.18vw;
  }

  .text-wrapper_4 {
    padding: 1.41vw 0 68.96vw;
    background-color: #ffffff;
    border-radius: 1.15vw;
    width: 43.54vw;
    position: absolute;
    left: 0;
    top: 0;
  }

  .group_12 {
    position: absolute;
    left: 3.44vw;
    top: 8vw;
  }

  .section_2 {
    padding: 2.45vw 1.15vw 28.23vw 1.77vw;
    background-color: #ffffff;
    border-radius: 1.15vw;
    width: 43.8vw;
    position: absolute;
    left: 1.72vw;
    bottom: 42vw;
  }

  .section_3 {
    padding: 2.4vw 2.03vw 0.89vw;
    background-color: #ffffff;
    border-radius: 1.15vw;
    width: 43.65vw;
    position: absolute;
    left: 1.51vw;
    top: 86vw;
  }

  .text_36 {
    color: #fdb913;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 2.29vw;
    letter-spacing: 0.094vw;
    position: absolute;
    right: 0.26vw;
    bottom: 25.42vw;
  }

  .text_37 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.46vw;
    letter-spacing: 0.063vw;
    position: absolute;
    right: 0;
    bottom: 22.92vw;
  }

  .image {
    width: 34.27vw;
    height: 23.13vw;
  }

  .image_1 {
    width: 33.13vw;
    height: 9.48vw;
    position: absolute;
    left: 0;
    right: 1.2vw;
    top: 0;
  }

  .text_39 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 2.92vw;
    letter-spacing: 0.063vw;
  }

  .text_40 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 2.92vw;
    letter-spacing: 0.063vw;
  }

  .group_31 {
    margin-left: 2.81vw;
    align-self: center;
    width: 18.44vw;
  }

  .view_6 {
    margin-left: 2.86vw;
  }

  .image_7 {
    width: 11.61vw;
    height: 11.67vw;
  }

  .text_49 {
    margin-top: 1.35vw;
    color: #808285;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 1.46vw;
    letter-spacing: 0.063vw;
  }

  .text_3 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.51vw;
    letter-spacing: 0.063vw;
  }

  .text_4 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.46vw;
    letter-spacing: 0.063vw;
  }

  .text_5 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.46vw;
    letter-spacing: 0.063vw;
  }

  .text_6 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.46vw;
    letter-spacing: 0.063vw;
  }

  .group_5 {
    margin-top: 0.23vw;
    flex-shrink: 0;
  }

  .group_7 {
    margin-left: 12.03vw;
    height: 4.06vw;
  }

  .text_12 {
    color: #f7a51e;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 1.51vw;
  }

  .text_13 {
    color: #f7a51e;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 1.51vw;
  }

  .text_14 {
    margin-left: 3.39vw;
    color: #282828;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 2.19vw;
    letter-spacing: 0.094vw;
  }

  .text_14_1 {
    color: #282828;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 2.19vw;
    margin-top: 5vw;
    letter-spacing: 0.094vw;
  }

  .text_15 {
    color: #282828;
    margin: 15px auto;
    font-weight: bold;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    letter-spacing: 0.063vw;
  }

  .text_16 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    letter-spacing: 0.063vw;
  }

  .text_17 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    letter-spacing: 0.063vw;
    text-indent: 2rem;
  }

  .text_18 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    letter-spacing: 0.063vw;
    text-indent: 4rem;
  }

  .text_19 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    letter-spacing: 0.063vw;
  }

  .text_20 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 3.13vw;
    letter-spacing: 0.063vw;
  }

  .text_21 {
    margin-left: 0.42vw;
    align-self: flex-start;
    color: #282828;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 2.19vw;
    letter-spacing: 0.094vw;
  }

  .group_13 {
    margin-top: 1.56vw;
  }

  .text_25 {
    align-self: flex-start;
    color: #282828;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 2.24vw;
    letter-spacing: 0.094vw;
  }

  .group_14 {
    margin-top: 2.76vw;
    /* padding: 0 0.47vw; */
  }

  .group_18 {
    margin-top: 3.02vw;
    padding: 0 0.42vw;
  }

  .group_19 {
    margin-top: 7.6vw;
    padding: 0 0.16vw;
  }

  .group_25 {
    align-self: center;
    width: 14.27vw;
  }

  .view_1 {
    margin-left: 1.88vw;
  }

  .group_33 {
    margin-top: 3.85vw;
    padding-left: 0.1vw;
  }

  .group_6 {
    margin-top: 0.16vw;
    flex-shrink: 0;
  }

  .text_9 {
    margin-left: 11.3vw;
    color: #f7a51e;
    font-size: 2.29vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 2.24vw;
  }

  .text_10 {
    color: #f7a51e;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 2.03vw;
  }

  .text_11 {
    color: #f7a51e;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 2.03vw;
  }

  .text_22 {
    color: #fdb913;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 3.13vw;
    letter-spacing: 0.063vw;
  }

  .text_23 {
    color: #fdb913;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 3.13vw;
    letter-spacing: 0.063vw;
  }

  .text_24 {
    color: #fdb913;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 3.13vw;
    letter-spacing: 0.063vw;
  }

  .group_16 {
    margin-right: 3.23vw;
    width: 20vw;
    padding-right: 3vw;
  }

  .text_31 {
    color: #fdb913;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 2.29vw;
    letter-spacing: 0.094vw;
  }

  .text_32 {
    margin-left: 0.21vw;
    margin-top: 0.78vw;
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.51vw;
    letter-spacing: 0.063vw;
  }

  .text_34 {
    color: #ffcb05;
    font-size: 1.77vw;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 1.41vw;
    letter-spacing: 0.071vw;
  }

  .text_35 {
    margin-top: 0.68vw;
    color: #ffcb05;
    font-size: 1.09vw;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 1.04vw;
  }

  .group_28 {
    margin-top: 4.48vw;
  }

  .text_46 {
    margin-top: 0.63vw;
    align-self: flex-start;
    color: #808285;
    font-size: 1.2vw;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 1.3vw;
  }

  .text_48 {
    margin-top: 0.68vw;
    color: #808285;
    font-size: 1.2vw;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 1.15vw;
  }

  .text_7 {
    color: #f7a51e;
    font-size: 2.29vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 2.08vw;
  }

  .text_8 {
    margin: 0.47vw 0 0.16vw 0.47vw;
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.46vw;
    letter-spacing: 0.063vw;
  }

  .text_26 {
    color: #fdb913;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 2.29vw;
    letter-spacing: 0.094vw;
  }

  .text_27 {
    margin-top: 0.78vw;
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 1.46vw;
    letter-spacing: 0.063vw;
  }

  .text_28 {
    margin-left: 0.21vw;
    align-self: flex-start;
    color: #fdb913;
    font-size: 2.34vw;
    font-family: Source Han Sans CN;
    font-weight: 800;
    line-height: 4vw;
    letter-spacing: 0.094vw;
  }

  .text_42 {
    margin-top: 0.63vw;
    align-self: flex-start;
    color: #808285;
    font-size: 1.2vw;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 0.94vw;
  }

  .text_44 {
    margin-top: 0.78vw;
    color: #808285;
    font-size: 1.2vw;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 1.25vw;
  }

  .image_5 {
    margin-bottom: 0.16vw;
    width: 1.88vw;
    height: 1.88vw;
  }

  .text_45 {
    margin-left: 0.83vw;
    margin-top: 0.89vw;
    color: #282828;
    font-size: 1.2vw;
    font-family: Source Han Sans CN;
    line-height: 1.15vw;
  }

  .image_6 {
    margin-bottom: 0.16vw;
    width: 1.93vw;
    height: 2.34vw;
  }

  .text_47 {
    margin: 1.3vw 10.63vw 0 0.99vw;
    color: #282828;
    font-size: 1.2vw;
    font-family: Source Han Sans CN;
    line-height: 1.2vw;
  }

  .text_29 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 2.92vw;
    letter-spacing: 0.063vw;
  }

  .text_30 {
    color: #282828;
    font-size: 1.56vw;
    font-family: Source Han Sans CN;
    line-height: 2.92vw;
    letter-spacing: 0.063vw;
  }

  .image_3 {
    width: 2.08vw;
    height: 2.19vw;
  }

  .text_41 {
    margin-left: 0.78vw;
    margin-top: 1.04vw;
    color: #282828;
    font-size: 1.2vw;
    font-family: Source Han Sans CN;
    line-height: 1.15vw;
  }

  .image_4 {
    width: 2.6vw;
    height: 2.19vw;
  }

  .text_43 {
    margin: 1.04vw 6.56vw 0 0.31vw;
    color: #282828;
    font-size: 1.2vw;
    font-family: Source Han Sans CN;
    line-height: 1.15vw;
  }
</style>
