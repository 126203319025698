<template>
  <div class="flex-col page">
<!--    <img-->
<!--      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16545053744645401664.png"-->
<!--      class="image"-->
<!--    />-->
    <el-carousel height="30vw">
      <el-carousel-item >
        <el-image style="height: 100%;width: 100%"  src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/publicImage/wwwBanner.png" ></el-image>
      </el-carousel-item>
  <!--    <el-carousel-item >
        <el-image style="height: 100%;width: 100%"  src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/publicImage/wwwHome.png" ></el-image>
      </el-carousel-item> -->
      <el-carousel-item >
        <el-image style="height: 100%;width: 100%"  src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/publicImage/1674980261085banjiabanner.jpg"></el-image>
      </el-carousel-item>
      <el-carousel-item >
        <el-image style="height: 100%;width: 100%"  src="https://xyj-pic.oss-cn-shenzhen.aliyuncs.com/qiye1654669062408banner3.jpg"></el-image>
      </el-carousel-item>
	  
    </el-carousel>
    <div class="flex-col group">
      <span class="text">全面关注家庭服务领域</span>
      <div class="flex-col group_1">
        <span class="text_1">小羽佳家政·成就美好家庭</span>
        <span class="text_2">
          居家保洁、收纳整理、搬家搬场、保姆月嫂、家电清洗、维修拆装，小羽佳关注家庭服务的方方面面，为提供品质家庭生活而努力。
        </span>
      </div>
      <div class="flex-row group_2">
        <div class="flex-col">
          <div class="flex-col">
            <span class="text_3">在楼下的社区管家</span>
            <div class="flex-col text-wrapper"><span class="text_4">保洁/搬家/清洗/保姆/月嫂/政企服务</span></div>
          </div>
          <div class="flex-row group_5">
            <div class="flex-col items-center">
              <img
                src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/publicImage/1675059037460xiaoyujialogo.png"
                class="image_1"
              />
              <span class="text_5">小程序</span>
            </div>
            <div class="flex-col items-center group_7">
              <img
                src="https://xyj-pic.oss-cn-shenzhen.aliyuncs.com/qiye16568982755175pQOBkSA9ZRXNfkl.png"
                class="image_1"
              />
              <span class="text_6">小羽佳家政APP</span>
            </div>
          </div>
        </div>
        <div class="flex-col items-center group_8">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16548479046292735143.png"
            class="image_4"
          />
          <span class="text_7">居家保洁</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16548479046319200937.png"
            class="image_4 image_6"
          />
        </div>
        <div class="flex-col items-center group_9">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16548479046306751867.png"
            class="image_4"
          />
          <span class="text_8">收纳整理</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16548479046313181077.png"
            class="image_4 image_8"
          />
        </div>
        <div class="flex-col items-center group_10">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16548479046311031222.png"
            class="image_4"
          />
          <span class="text_9">搬家搬场</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16548479046325698067.png"
            class="image_4 image_10"
          />
        </div>
      </div>
      <div class="justify-between group_11">
        <span class="text_10">保姆月嫂</span>
        <span class="text_11">家电清洗</span>
        <span class="text_12">维修拆装</span>
      </div>
    </div>
    <div class="flex-col items-start group_12">
      <span class="text_13 text_14">CONTACT US</span>
      <span class="text_13 text_15">联系我们</span>
    </div>
    <div class="justify-between group_13">
      <div class="flex-row group_14">
        <div class="flex-row">
          <div class="flex-col group_16">
            <div class="flex-col group_17">
              <div class="flex-row">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546724381378723023.png"
                  class="image_11"
                />
                <span class="text_13 text_16">客户服务</span>
              </div>
              <span class="text_13 text_17">400-6655-888</span>
            </div>
            <div class="flex-col group_19">
              <div class="flex-row">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546724381381913793.png"
                  class="image_12"
                />
                <span class="text_13 text_18">人才招聘</span>
              </div>
              <span class="text_19">zhaopin@xiaoyujia.com</span>
            </div>
          </div>
          <div class="flex-col group_21">
            <div class="section_1"><!--*--></div>
            <div class="section_1 view_2"><!--*--></div>
          </div>
        </div>
        <div class="flex-col group_22">
          <div class="flex-col">
            <div class="flex-row group_17">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546724381391032631.png"
                class="image_13"
              />
              <span class="text_13 text_20">品牌合作</span>
            </div>
            <span class="text_21">franchise@xiaoyujia.com</span>
          </div>
          <div class="flex-col group_24">
            <div class="flex-row">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546724381392437745.png"
                class="image_14"
              />
              <span class="text_13 text_22">联系地址</span>
            </div>
            <span class="text_23">厦门市思明区人才中心A栋6楼601</span>
          </div>
        </div>
      </div>
      <div class="flex-col items-center">
        <img
          src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/UISCK/wwwcode.png"
          class="image_15"
        />
        <span class="text_24">企业微信</span>
        <!-- <div class="div_25">
          <span>知识产权</span>
          <span>协议公告</span>
          <span>隐私政策</span>
        </div>
        <div>小羽佳小程序|小羽佳家政|<a href="https://hy.xiaoyujia.com/Product/Details?pid=113&city=1">管道疏通</a></div> -->
      </div>
    </div>
    <div class="lh100 text-c">@1999-2024 www.xiaoyujia.com 厦门小羽佳家政股份有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备11006005号-1</a></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {

    },
  methods: {},
};
</script>

<style scoped lang="css">
.text_13 {
  text-transform: uppercase;
}
.image_4 {
  width: 14.58vw;
  height: 9.95vw;
}
.group_17 {
  padding: 0 0.26vw;
}
.section_1 {
  background-color: #939598;
  height: 6.93vw;
}
.image_1 {
  width: 8.75vw;
  height: 8.75vw;
}
.page {
  /* padding-bottom: 6.09vw; */
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.image {
  width: 100vw;
  height: 34.5833vw;
}
.group {
  margin-top: 4.01vw;
  padding-left: 6.82vw;
  padding-right: 6.61vw;
}
.group_12 {
  margin-left: 7.03vw;
  margin-top: 2.4vw;
  align-self: flex-start;
}
.group_13 {
  margin-top: 3.13vw;
  padding: 0 7.29vw;
}
.text {
  margin-right: 11.51vw;
  align-self: flex-end;
  color: #282828;
  font-size: 3.18vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 3.18vw;
}
.group_1 {
  margin-top: 1.25vw;
  height: 2.76vw;
}
.group_2 {
  margin-top: 1.25vw;
}
.group_11 {
  margin-right: 4vw;
  margin-top: 0.99vw;
  align-self: flex-end;
  width: 43.28vw;
}
.text_14 {
  color: #ffcb05;
  font-size: 1.88vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.075vw;
}
.text_15 {
  margin-top: 0.68vw;
  color: #ffcb05;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.09vw;
}
.group_14 {
  margin-top: 0.36vw;
}
.group_26 {
  margin-right: 12.5vw;
  margin-bottom: 0.47vw;
}
.text_1 {
  margin-top: 0.52vw;
  align-self: flex-start;
  color: #282828;
  font-size: 2.29vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 2.24vw;
}
.text_2 {
  margin-top: -2.76vw;
  align-self: flex-end;
  color: #282828;
  font-size: 0.94vw;
  font-family: Source Han Sans CN;
  line-height: 0.94vw;
}
.group_8 {
  margin-left: 6.51vw;
  margin-top: 0.31vw;
}
.group_9 {
  margin-left: 4.58vw;
  margin-top: 0.31vw;
}
.group_10 {
  margin-left: 4.79vw;
  margin-top: 0.31vw;
}
.text_10 {
  color: #000;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.15vw;
  letter-spacing: 0.088vw;
}
.text_11 {
  color: #000;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.09vw;
  letter-spacing: 0.088vw;
}
.text_12 {
  color: #000;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.09vw;
  letter-spacing: 0.088vw;
}
.group_22 {
  margin-left: 2.97vw;
  align-self: center;
  width: 19.22vw;
}
.image_15 {
  width: 12.19vw;
  height: 12.24vw;
}
.text_24 {
  margin-top: 0.5vw;
  color: #808285;
  font-size: 1.31vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.51vw;
  letter-spacing: 0.065vw;
}
.group_5 {
  margin-top: 2.81vw;
  padding-left: 3.49vw;
  padding-right: 1.88vw;
}
.text_7 {
  margin-top: 0.99vw;
  color: #000;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.09vw;
  letter-spacing: 0.088vw;
}
.image_6 {
  margin-top: 1.88vw;
}
.text_8 {
  margin-top: 0.99vw;
  color: #000;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.15vw;
  letter-spacing: 0.088vw;
}
.image_8 {
  margin-top: 1.82vw;
}
.text_9 {
  margin-top: 0.99vw;
  color: #000;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.09vw;
  letter-spacing: 0.088vw;
}
.image_10 {
  margin-top: 1.88vw;
}
.group_16 {
  align-self: center;
  width: 14.9vw;
}
.group_21 {
  margin-left: 1.98vw;
  width: 0.1vw;
}
.group_24 {
  margin-top: 4.06vw;
  padding-left: 0.1vw;
}
.text_3 {
  align-self: center;
  color: #ffcb05;
  font-size: 1.77vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.77vw;
  letter-spacing: 0.14vw;
}
.text-wrapper {
  margin-top: 1.04vw;
  padding: 0.68vw 0 0.52vw;
  background-color: #ffdd00;
  border-radius: 0.96vw;
}
.group_7 {
  margin-left: 3.75vw;
}
.group_19 {
  margin-top: 4.69vw;
}
.view_2 {
  margin-top: 1.41vw;
}
.text_21 {
  margin-top: 0.68vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.35vw;
}
.text_23 {
  margin-top: 0.73vw;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.15vw;
}
.text_4 {
  margin-left: 1.46vw;
  margin-right: 2.24vw;
  color: #555555;
  font-size: 1.48vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.56vw;
}
.text_5 {
  margin-top: 2.08vw;
  color: #000;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.09vw;
  letter-spacing: 0.088vw;
}
.text_6 {
  margin-top: 2.24vw;
  color: #000;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 0.89vw;
  letter-spacing: 0.088vw;
}
.text_17 {
  margin-top: 0.68vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 0.99vw;
}
.text_19 {
  margin-top: 0.83vw;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.3vw;
}
.image_13 {
  width: 1.98vw;
  height: 1.98vw;
}
.text_20 {
  margin-left: 0.83vw;
  margin-top: 0.94vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.15vw;
}
.image_14 {
  margin-bottom: 0.16vw;
  width: 2.03vw;
  height: 2.45vw;
}
.text_22 {
  margin: 1.41vw 11.04vw 0 1.04vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}
.image_11 {
  width: 2.19vw;
  height: 2.29vw;
}
.text_16 {
  margin-left: 0.78vw;
  margin-top: 1.09vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}
.image_12 {
  width: 2.71vw;
  height: 2.29vw;
}
.text_18 {
  margin: 1.09vw 6.88vw 0 0.31vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}
.div_25{
  font-size: 1.45vw;
  font-weight: 600;
  line-height: 2;
}
.div_25>>>span{
  margin-right: 1vw;
}
</style>
