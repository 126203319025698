import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../pages/index/index.vue';
import NewsDetail from '../pages/newsDetail/newsDetail.vue';
import About from '../pages/about/aboutus';
import NewsList from '../pages/newsList/newsList.vue';
import Brand from '../pages/brand/brand.vue';
import Friends from '../pages/friends/friends.vue';
import menuIndex from '../pages/index/menuIndex.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'menuIndex',
    component: menuIndex,
    children: [
      {
        path: '/',
        name: 'index',
        component: Index,
      },
      {
        path: '/aboutus',
        name: 'about',
        component: About,
      },
      {
        path: '/news',
        name: 'newsList',
        component: NewsList,
      },
      {
        path: '/xyjBrand',
        name: 'brand',
        component: Brand,
      },
      {
        path: '/franchise',
        name: 'friends',
        component: Friends,
      },
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: NewsDetail,
      },
    ]
  },



];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview',  + to.fullPath]);
    }
  }
  next();
});


export default router;
