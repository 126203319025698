<template>
  <div class="flex-col page">
    <div class="flex-col">
      <div class="flex-col items-start section_1">
        <div class="flex-col items-start">
          <span class="text">2024小羽佳合作伙伴计划</span>
          <span class="text_1">新模式·新机遇·新时代</span>
        </div>
        <div class="flex-col items-center text-wrapper"><span class="text_2">了解详情</span></div>
      </div>
      <div class="flex-col items-start text-wrapper_1">
        <span class="text_3 text_4"> <span @click="goList">{{news.typeName}}</span> 》 {{news.title}}</span>
      </div>
    </div>
    <span class="text_5">{{news.title}}</span>
    <div class="justify-between group_2">
      <span class="text_3 text_6">{{news.creatDate}}</span>
      <div class="flex-row">
        <img
          src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16545673877397601883.png"
          class="image"
        />
        <span class="text_3 text_7">{{ news.viewNum }}</span>
      </div>
    </div>
    <div class="flex-col group_4">
      <div class="flex-col items-start">
        <span class="text_3 text_8" v-html="news.cms"></span>

      </div>
      <div class="flex-row group_6">
        <div class="flex-col items-center text-wrapper_2 view" @click="jump(news.prevPage)"><span class="text_3 text_15">上一篇</span></div>
        <div class="flex-col items-center text-wrapper_3" @click="this.goList"><span class="text_3 text_17">返回列表</span></div>
        <div class="flex-col items-center text-wrapper_2 view_1" @click="jump(news.nextPage)"><span class="text_3 text_15">下一篇</span></div>
      </div>
    </div>
    <div class="flex-col items-start group_7">
      <span class="text_3 text_19">CONTACT US</span>
      <span class="text_3 text_20">联系我们</span>
    </div>
    <div class="justify-between group_8">
      <div class="flex-row group_9">
        <div class="flex-row">
          <div class="flex-col group_11">
            <div class="flex-col group_12">
              <div class="flex-row">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743342820070549.png"
                  class="image_1"
                />
                <span class="text_3 text_21">客户服务</span>
              </div>
              <span class="text_3 text_22">400-6655-888</span>
            </div>
            <div class="flex-col group_14">
              <div class="flex-row">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743342848337997.png"
                  class="image_2"
                />
                <span class="text_3 text_23">人才招聘</span>
              </div>
              <span class="text_24">zhaopin@xiaoyujia.com</span>
            </div>
          </div>
          <div class="flex-col group_16">
            <div class="section_2"><!--*--></div>
            <div class="section_2 view_4"><!--*--></div>
          </div>
        </div>
        <div class="flex-col group_17">
          <div class="flex-col">
            <div class="flex-row group_12">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743342844422779.png"
                class="image_3"
              />
              <span class="text_3 text_25">品牌合作</span>
            </div>
            <span class="text_26">franchise@xiaoyujia.com</span>
          </div>
          <div class="flex-col group_19">
            <div class="flex-row">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743342847027652.png"
                class="image_4"
              />
              <span class="text_3 text_27">联系地址</span>
            </div>
            <span class="text_28">厦门市思明区人才中心A栋6楼601</span>
          </div>
        </div>
      </div>
      <div class="flex-col items-center group_21">
        <img
          src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/UISCK/wwwcode.png"
          class="image_5"
        />
        <span class="text_29">扫一扫 了解更多</span>
      </div>
    </div>
    <div class="lh100 text-c">@1999-2024 www.xiaoyujia.com 厦门小羽佳家政股份有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备11006005号-1</a></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      id:this.$route.query.id,
      news:[]
    };
  },
  created() {
    this.getdata();
    this.updateCount();

  },
  methods: {
    updateCount(){
      this.$getData("updateCount", {id:this.id}).then(res => {
      })
    },
    jump(page){
      this.$router.push({
        name: "newsDetail",
        query:{
          id:page
        }
      })
      window.location.reload();
    },
    getdata(){
      this.$getData("getCmsInfo", {id:this.id}).then(res => {
        if (res.status == 200) {
          this.news = res.data;
          document.title = this.news.title;
        } else {
          this.$message.error("查询失败，" + res.msg);
        }
      })
    },
    goList(){
      this.$router.push({
        name: "newsList",
        query:{
          typeName:this.news.typeName
        }
      })
    }

  },
};
</script>

<style scoped lang="css">
.text_3 {
  text-transform: uppercase;
}
.text-wrapper_2 {
  padding: 0.94vw 0 1.04vw;
  background-color: #e4e4e4;
  border-radius: 1.66vw;
  width: 9.94vw;
  height: 3.75vw;
}
.group_12 {
  padding: 0 0.26vw;
}
.section_2 {
  background-color: #939598;
  height: 6.92vw;
}
.text_15 {
  color: #000;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  line-height: 1.77vw;
  letter-spacing: 0.075vw;
}
.page {
  /* padding-bottom: 4.42vw; */
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.text_5 {
  margin-top: 4.42vw;
  align-self: center;
  color: #c90202;
  font-size: 2.19vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 4.16vw;
  letter-spacing: 0.17vw;
}
.group_2 {
  margin-top: 2.6vw;
  padding-bottom: 1.25vw;
  align-self: center;
  width: 72.28vw;
  border-bottom: solid 0.052vw #282828;
}
.group_4 {
  margin-left: 17.89vw;
  margin-top: 3.02vw;
  align-self: flex-start;
}
.group_7 {
  margin-left: 6.61vw;
  margin-top: 3.02vw;
  align-self: flex-start;
}
.group_8 {
  margin-left: 6.87vw;
  margin-top: 2.12vw;
  align-self: flex-start;
  width: 72.83vw;
}
.section_1 {
  padding: 7.39vw 6.71vw 16.39vw;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16545053744645401664.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text-wrapper_1 {
  padding: 2.18vw 0 1.87vw;
  background-color: #ffdd00;
}
.text_6 {
  color: #4b4b4b;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}
.group_6 {
  margin-top: 4.89vw;
}
.text_19 {
  color: #ffcb05;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.075vw;
}
.text_20 {
  margin-top: 0.68vw;
  color: #ffcb05;
  font-size: 1.14vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.09vw;
}
.group_9 {
  margin-top: 0.36vw;
}
.group_21 {
  margin-bottom: 0.47vw;
}
.text-wrapper {
  margin-left: 0.16vw;
  margin-top: 2.65vw;
  padding: 0.52vw 0 0.47vw;
  background-color: #ffdd00;
  border-radius: 0.96vw;
  width: 6.81vw;
}
.text_4 {
  margin-left: 1.35vw;
  color: #000;
  font-size: 2.12vw;
  font-family: Source Han Sans CN;
  line-height: 3.02vw;
}
.image {
  flex-shrink: 0;
  width: 1.14vw;
  height: 1.14vw;
}
.text_7 {
  margin-left: 0.31vw;
  color: #4b4b4b;
  font-size: 1.51vw;
  font-family: Source Han Sans CN;
  line-height: 1.14vw;
}
.text_8 {
  width: 95%;
  margin-left: -100px;
  color: #282828;
  /*font-size: 1.87vw;*/
  font-family: Source Han Sans CN;
  line-height: 1.77vw;
}
.text_9 {
  margin-top: 1.14vw;
  color: #282828;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  line-height: 1.93vw;
}
.text_10 {
  margin-top: 1.04vw;
  color: #282828;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  line-height: 1.93vw;
}
.text_11 {
  margin-top: 0.99vw;
  color: #282828;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  line-height: 1.93vw;
}
.text_12 {
  margin-top: 1.04vw;
  color: #282828;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  line-height: 1.93vw;
}
.text_13 {
  margin-top: 1.93vw;
  color: #282828;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  line-height: 0.31vw;
}
.text_14 {
  margin-top: 1.87vw;
  color: #282828;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  line-height: 1.77vw;
}
.view {
  margin-left: 13.26vw;
}
.text-wrapper_3 {
  margin-left: 4.42vw;
  padding: 0.99vw 0 1.04vw;
  background-color: #e4e4e4;
  border-radius: 1.66vw;
  width: 9.94vw;
  height: 3.75vw;
}
.view_1 {
  margin-left: 4.89vw;
}
.group_17 {
  margin-left: 2.97vw;
  align-self: center;
  width: 19.19vw;
}
.image_5 {
  width: 12.17vw;
  height: 12.22vw;
}
.text_29 {
  margin-top: 1.4vw;
  color: #808285;
  font-size: 1.61vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.51vw;
  letter-spacing: 0.065vw;
}
.text {
  color: #282828;
  font-size: 2.76vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 2.65vw;
  letter-spacing: 0.21vw;
}
.text_1 {
  margin-top: 2.03vw;
  color: #ffcb05;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  line-height: 1.46vw;
  letter-spacing: 0.12vw;
}
.text_2 {
  color: #ffffff;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 0.99vw;
  letter-spacing: 0.08vw;
}
.text_17 {
  color: #000;
  font-size: 1.87vw;
  font-family: Source Han Sans CN;
  line-height: 1.72vw;
  letter-spacing: 0.075vw;
}
.group_11 {
  align-self: center;
  width: 14.88vw;
}
.group_16 {
  margin-left: 1.98vw;
  width: 0.1vw;
}
.group_19 {
  margin-top: 4.06vw;
  padding-left: 0.1vw;
}
.group_14 {
  margin-top: 4.68vw;
}
.view_4 {
  margin-top: 1.4vw;
}
.text_26 {
  margin-top: 0.68vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.35vw;
}
.text_28 {
  margin-top: 0.73vw;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.14vw;
}
.text_22 {
  margin-top: 0.68vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 0.99vw;
}
.text_24 {
  margin-top: 0.83vw;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.3vw;
}
.image_3 {
  width: 1.98vw;
  height: 1.98vw;
}
.text_25 {
  margin-left: 0.83vw;
  margin-top: 0.94vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.14vw;
}
.image_4 {
  margin-bottom: 0.16vw;
  width: 2.03vw;
  height: 2.44vw;
}
.text_27 {
  margin: 1.4vw 11.03vw 0 1.04vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}
.image_1 {
  width: 2.18vw;
  height: 2.29vw;
}
.text_21 {
  margin-left: 0.78vw;
  margin-top: 1.09vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}
.image_2 {
  width: 2.71vw;
  height: 2.29vw;
}
.text_23 {
  margin: 1.09vw 6.87vw 0 0.31vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}
</style>
