<template>
  <div class="flex-col page">
    <el-menu
      :default-active="activeIndex2"
      class="sidebar-top"
      mode="horizontal"
      @select="handleSelect"
      text-color="#1a1600"
      :router="true"
      active-text-color="#ffd04b">

      <div style="text-align: center">
        <el-image style=" float: left;height: 5vw;margin-left: 10%;" src="https://xyj-pic.oss-cn-shenzhen.aliyuncs.com/qiye1654755111432logo2.jpg"></el-image>
        <el-menu-item index="/">首页</el-menu-item>
<!--        <el-menu-item index="/xyjBrand">品牌</el-menu-item>-->
        <el-menu-item index="/franchise">合伙人</el-menu-item>
        <el-menu-item index="/news">动态</el-menu-item>
        <el-menu-item index="/aboutus">关于我们</el-menu-item>
      </div>

    </el-menu>

    <div class="content-box" >
      <div class="content">
        <transition name="move" mode="out-in">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeIndex2: '1',
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  },
};
</script>

<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;
}
.sidebar-top{
  ::v-deep.el-menu-item{
    width: 15vw;
    display:inline-block;
    font-size: 1.3vw;
  }
  .el-menu-item{
    margin-top: 0.5vw;
  }
  /*width: 100%;*/
  /*position:fixed;!*固定作用*!*/

}
.page {
  // padding-bottom: 6.09vw;
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
</style>
