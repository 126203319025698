import axios from 'axios';
import Vue from 'vue'
import api from "./api"


// axios.defaults.baseURL = "http://localhost:8063";
axios.defaults.baseURL = "https://biapi.xiaoyujia.com";
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.timeout = 200000;
// // 添加请求拦截器(axios请求前)
axios.interceptors.request.use(config => {
    return config;
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error);
});
// 添加响应拦截器(axios请求后)
axios.interceptors.response.use(response => {

    return response.data;
}, error => {
    console.log(error);
    // 对响应错误做点什么
    return Promise.reject(error);
});

const postData = (url, data, config = {}) => {
    return axios.post(api[url], data, config);
};
const getData = (url, params) => {
    return axios.get(api[url], {
        params
    });
};
const postUrl = (url, path, data, config = {}) => {
    return axios.post(api[url] + path, data, config);
};
const getUrl = (url, path, params) => {
    return axios.get(api[url] + path, {
        params
    });
};

Vue.prototype.$postData = postData;
Vue.prototype.$postUrl = postUrl;
Vue.prototype.$getData = getData;
Vue.prototype.$getUrl = getUrl;


export default {
    postData, getData, postUrl, getUrl
};
